<template>
  <div class="create-category">
    <CategoryAppbar />
    <div class="ma-4">
      <CategoryForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Event category
 * ==================================================================================
 **/

import CategoryAppbar from '@/views/Home/Event/Categories/components/CategoryAppbar'
import CategoryForm from '@/views/Home/Event/Categories/components/CategoryForm'

export default {
  components: {
    CategoryAppbar,
    CategoryForm,
  },
}
</script>
<style lang="scss">
.create-category {
  //
}
</style>
